<template>
    <div class="h-full profile-box profile-box-blockchain shadow-lg p-5 transition ease-in duration-75 transform hover:scale-105">
        <h2 class="font-shadow-sm text-xl mb-2 text-white font-black uppercase">Chainist</h2>
        <p class="mt-64 text-white font-medium">
            Yes, this blockchain thing. It got me in in mid of 2017. I am totally into it, since then.
        </p>
        <p class="text-white font-medium mt-3">
            <span class="font-bold italic">Why am I doing it?</span><br>
            I like the concept of decentralization, self-organization and optimization.
            And because there is so much bullshit out there in the (still) evolving industry.
            To be clear, I am into it not because of the bullshit, but to pull those aside in order
            to point people to the non-bullshit direction.
        </p>

        <p class="text-white font-medium mt-3"> So I decided to start <a class="underline hover:no-underline" href="https://chainist.de" title="Blockchain training and education">chainist.de</a>
            to educate people and organizations around Bitcoin and Blockchain. A chainist is someone who looks at the positive things in blockchain.
        </p>
        <p class="text-white font-medium mt-3">
            <span class="font-bold italic">What am I doing?</span><br>

            I stopped counting events which I've organized throughout the years. 30, 40 or more?
            I've organised events for the ministry of economy of the Rhineland-Palatinate state, city of
            Mainz and various other institutions and organizations.
        </p>
        <p class="text-white font-medium mt-3">
            <span class="font-bold italic">What else?</span><br>
            All along the way I have met awesome people with diverse backgrounds.
            All eager to learn something new and who don't take ones ego too big.
            Check out the website (German) to learn more. All the content is for free.
            Sorry for my English reading folks!
        </p>
        <div class="mt-5">
            <a class="btn btn-white" title="Blockchain training and education" href="https://chainist.de">chainist.de</a>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Blockchain",
    }
</script>

<style scoped>

</style>
