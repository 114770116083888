<template>
    <div class="h-full profile-box profile-box-digitalist p-5 transition ease-in duration-75 transform hover:scale-105">
        <h2 class="font-shadow-sm text-xl mb-2 text-white font-black uppercase">Digitalist</h2>
        <p class="mt-64 text-white font-medium">
            What's Digitalist?
            Over the course of my private and professional life I often (and still)
            come across the question <em>What I am good at?</em>
        </p>
        <p class="text-white font-medium mt-3">
            Am I a developer, an agile coach a scrum master a designer?
            All the skills which I have built up so far helped me to create digital product.
            Be it automating business processes, building a team or founding a startup.
            All of them end in a tangible digital product. That's the reason why I call myself a digitalist.
        </p>
        <p class="text-white font-medium mt-3">
            <span class="italic font-bold">Why do customers love to work with me?</span> <br>
            I smile, I keep going and get things done. What is life and a job without humor and fun?
        </p>
        <p class="text-white font-medium mt-3">
            Get in touch? Give me a call or drop me a line in my inbox. Meanwhile follow me on <a class="underline hover:no-underline" href="https://twitter.com/jobnomade">twitter</a> and
            connect with me on <a class="underline hover:no-underline" href="https://www.linkedin.com/in/trinhanvu" title="Nhan Vu linkedIn Profile">linkedIn</a>.
        </p>
        <div class="mt-5">
            <a class="btn btn-white" href="mailto:nhan@nhanvu.de">Send me an email</a>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Digitalist"
    }
</script>

<style scoped>

</style>
