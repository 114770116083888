<template>
  <div id="app">
      <Navbar>
      </Navbar>
      <div class="h-10 ml-auto mr-auto lg:mt-5 lg:mb-10 lg:w-2/3">
          <div class="lg:flex lg:mt-5 mt-3">
              <div class="lg:w-1/3 lg:m-2 mb-2">
                  <Developer></Developer>
              </div>
              <div class="lg:w-1/3 lg:m-2 mb-2">
                  <Blockchain></Blockchain>
              </div>
              <div class="lg:w-1/3 lg:m-2 mb-2">
                  <Digitalist></Digitalist>
              </div>
          </div>
          <footer class="p-3 lg:mt-20 mb-10 lg:flex">
              <Imprint>

              </Imprint>
              <Customers>

              </Customers>
          </footer>
      </div>

  </div>
</template>

<script>
import Developer from "@/components/Developer";
import Blockchain from "@/components/Blockchain";
import Digitalist from "@/components/Digitalist";
import Imprint from "@/components/Imprint";
import Customers from "@/components/Customers";
import Navbar from "@/components/Navbar";
export default {
  name: 'App',
  components: {
      Navbar,
      Customers,
      Imprint,
      Digitalist,
      Blockchain,
      Developer,
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-top: 0;
}
</style>
