<template>
    <div id="customers" class="lg:w-10/12 mt-5 lg:mt-0  text-white">
        <span class="font-bold uppercase tracking-wider block mb-5">Active customers</span>
      <div class="customer-logos grid grid-flow-row lg:grid-cols-4 grid-cols-2 grid-rows-2 gap-8">
        <div>
          <a href="https://www.printweb.de/" title="Digital Druckerei Frankfurt Colour Connection GmbH">
            <img alt="Logo Colour Connection GmbH" :src="'CC.png'">
          </a>
        </div>
        <div>
          <a href="https://bd-a.de" title="Big Data Analysis GmbH" >
            <img alt="Logo Big Data Analysis GmbH" :src="'BDA.png'">
          </a>
        </div>
      </div>
        <span class="font-bold uppercase tracking-wider block mb-5">Past customers</span>
        <div class="customer-logos grid grid-flow-row lg:grid-cols-4 grid-cols-2 grid-rows-2 gap-8">
            <div>
                <img alt="Logo Wirtschaftsministerium Rheinland-Pfalz" :src="'RLP.png'">
            </div>
            <div>
                <img alt="Logo ZDF" :src="'ZDF.png'">
            </div>
            <div>
                <img alt="Logo Betty Blocks" :src="'BB.png'">
            </div>
            <div>
                <img alt="Logo Stadt Mainz" :src="'MZ.png'">
            </div>
            <div>
                <img alt="Logo Anyblock Analytics GmbH" :src="'AA.png'">
            </div>
            <div>
                <img alt="Logo brainbot technologies AG" :src="'BBot.png'">
            </div>
            <div>
                <img alt="Logo innoblock" :src="'IB.png'">
            </div>
            <div>
                <img alt="Logo la-concept GmbH" :src="'LA.png'">
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Customers"
    }
</script>

<style scoped>

</style>
