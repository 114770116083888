<template>
    <header id="header" class="bg-black top-auto mr-auto p-3 ml-auto lg:w-2/3">
        <div class="flex sticky top-auto items-center justify-between">
            <div>
                <svg xmlns="http://www.w3.org/2000/svg" style="isolation:isolate" viewBox="0 0 180 180" class="h-12">
                    <defs>
                        <clipPath id="a">
                            <path d="M0 0h180v180H0z"/>
                        </clipPath>
                    </defs>
                    <g clip-path="url(#a)">
                        <path d="M56.698 114.615h-8.215V83.324q0-6.123-2.382-9.094-2.383-2.972-7.602-2.972-5.627 0-9.235 4.075-3.608 4.074-3.608 11.053v34.262q0 6.889-2.723 9.995-2.723 3.107-8.169 3.107-3.495 0-5.764-.405v-6.258l2.814.27q3.131 0 4.402-1.621 1.271-1.621 1.271-6.303V76.301q0-8.51-.273-10.401h7.716q.045.226.09 1.216.046.991.114 2.274t.159 4.84h.136Q30.194 65 41.131 65q7.852 0 11.709 4.21 3.858 4.209 3.858 12.944v32.461zM92.188 115.516q-8.941 0-13.888-2.679-4.946-2.679-7.17-8.757l7.216-1.981q1.18 3.782 4.629 5.583 3.449 1.801 9.394 1.801 11.981 0 11.981-8.33 0-4.277-4.788-6.64-4.788-2.364-13.183-2.364v-6.168q8.305 0 12.73-2.026 4.424-2.026 4.424-5.718 0-7.204-11.209-7.204-5.855 0-8.759 1.598-2.905 1.599-3.449 4.885l-7.352-.9q1.089-6.168 5.967-8.892Q83.61 65 92.369 65q8.986 0 14.228 3.444 5.242 3.445 5.242 9.388 0 2.926-1.657 5.312-1.656 2.386-4.561 3.895-2.904 1.508-6.172 1.868v.09q5.809.631 9.689 3.917 3.881 3.287 3.881 8.014 0 6.934-5.378 10.761-5.378 3.827-15.453 3.827zM168.25 114.615h-8.214V83.324q0-6.123-2.383-9.094-2.383-2.972-7.602-2.972-5.627 0-9.235 4.075-3.608 4.074-3.608 11.053v28.229h-8.169V76.301q0-8.51-.272-10.401h7.715q.045.226.09 1.216.046.991.114 2.274t.159 4.84h.136Q141.746 65 152.684 65q7.851 0 11.708 4.21 3.858 4.209 3.858 12.944v32.461z" fill="#E8E8E8"/>
                        <path d="M0 0h180v180H0V0zm10 10h160v160H10V10z" fill-rule="evenodd" fill="#E8E8E8"/>
                    </g>
                </svg>
            </div>
            <div class="flex">
                <div>
                    <a class="btn btn-white btn-sm mr-2 hover:bg-green-500" id="btn-send-email" title="Nhan Vu eine E-Mail schicken" href="mailto:nhan@nhanvu.de">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
                            <path d="M4 4h16a2 2 0 012 2v12a2 2 0 01-2 2H4a2 2 0 01-2-2V6c0-1.1.9-2 2-2zm16 3.38V6H4v1.38l8 4 8-4zm0 2.24l-7.55 3.77a1 1 0 01-.9 0L4 9.62V18h16V9.62z"/>
                        </svg>
                    </a>
                </div>
                <div>
                    <a class="btn btn-white btn-sm hover:bg-green-500 mr-2" id="btn-call-phone" title="Nhan Vu auf dem Handy anrufen +4915732742217" href="tel:+4915732742217">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="h-6">
                            <path d="M13.04 14.69l1.07-2.14a1 1 0 0 1 1.2-.5l6 2A1 1 0 0 1 22 15v5a2 2 0 0 1-2 2h-2A16 16 0 0 1 2 6V4c0-1.1.9-2 2-2h5a1 1 0 0 1 .95.68l2 6a1 1 0 0 1-.5 1.21L9.3 10.96a10.05 10.05 0 0 0 3.73 3.73zM8.28 4H4v2a14 14 0 0 0 14 14h2v-4.28l-4.5-1.5-1.12 2.26a1 1 0 0 1-1.3.46 12.04 12.04 0 0 1-6.02-6.01 1 1 0 0 1 .46-1.3l2.26-1.14L8.28 4zm7.43 5.7a1 1 0 1 1-1.42-1.4L18.6 4H16a1 1 0 0 1 0-2h5a1 1 0 0 1 1 1v5a1 1 0 0 1-2 0V5.41l-4.3 4.3z"/>
                        </svg>
                    </a>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
    export default {
        name: "Navbar"
    }
</script>

<style scoped>

</style>
