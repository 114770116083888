<template>
    <div class="h-full profile-box profile-box-developer p-5 transition ease-in duration-75 transform hover:scale-105">
        <h2 class="text-xl font-shadow-sm mb-2 text-white font-black uppercase">Developer</h2>
        <p class="mt-64 text-white font-medium">
            In the last 15 years I have crafted web applications for small and large enterprises.
            Besides working on customer projects,
            I constantly work on side projects to keep on learning.
        </p>
        <p class="mt-3 text-white font-medium">
            <span class="italic font-bold">Why am I coding?</span><br>
            Honestly, when I started to learn to code, I hated it. It never worked as expected.
            I have learned graphic design, and as a designer I was used to be <em>under control</em> and see results right away.
            With coding, you can't see immediate results. That was my perception when I started.
        </p>
        <p class="mt-3 font-medium text-white">
            After going through all the painful years of self-apprenticeship, <em>I'm enjoying coding</em>.
        </p>
        <p class="mt-3 text-white font-medium">
            <span class="italic font-bold">What's my favourite programming language?</span><br>
           I had my relationships with Java, Python, C# and .Net. Somehow I'am still sticking with PHP.
            Even though there are shiny options out there like elixir, Go etc. With PHP I get things quickly done.
            Especially using Laravel and its entire eco-system of tools like Laravel Forge, Nova, etc.
            For the interactive part with the user, I pick Vue.js and tailwind CSS.
        </p>
        <p class="mt-3 text-white font-medium">
            <span class="italic font-bold">What are you building?</span><br>
            REST APIs, web apps, system integrations. Learn more on my dedicated website.
        </p>
        <div class="mt-5">
            <a class="btn btn-white" href="https://vuncion.com">vuncion.com</a>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Developer"
    }
</script>

<style scoped>

</style>
