<template>
 <div class="lg:w-1/4 text-white">
     <span class="font-bold uppercase mb-5 block tracking-wider">Imprint</span><br>
     <span class="font-bold">Nhân Vũ </span><br>
     Kiedricher Str. 26<br>
     55218 Ingelheim<br>
     Germany<br>
 </div>
</template>

<script>
    export default {
        name: "Imprint"
    }
</script>

<style scoped>

</style>
